import React, { FC, PropsWithChildren } from 'react'

import { animated } from '@react-spring/web'
import styled from 'styled-components'

import { fontFamily } from 'common/constants'
import { buttonResetStyles } from 'common/styles/buttonReset'
import { StyledComponentsStyles } from 'common/types'
import { PrimaryButton } from 'components/designSystem/Button/PrimaryButton'
import { Typography } from 'components/designSystem/Typography/Typography'

import stars from './assets/stars.svg'
import { containerCss } from './containerCss'

export const InstallAppBannerContainer = styled(animated.a)<{
  $styles: StyledComponentsStyles
}>`
  ${containerCss};
`

export const CloseButton = styled.button`
  ${buttonResetStyles};
  padding-top: 19px;
  padding-bottom: 19px;
  ${({ theme }) => theme.paddingLeft}: var(--spacing-8px);
  ${({ theme }) => theme.paddingRight}: 10px;

  font-family: ${fontFamily};
`
export const Logo = styled.img.attrs(() => ({
  width: '50px',
}))`
  flex-shrink: 0;
`
export const Block = styled.div`
  flex-grow: 1;
  ${({ theme }) => theme.marginLeft}: 10px;
  ${({ theme }) => theme.marginRight}: 20px;
`

const StartSvg: FC = () => <img src={stars} />

export const StyledStarsRatingSvg = styled(StartSvg)`
  margin-top: 2px;
`
export const Title: FC<PropsWithChildren> = ({ children }) => (
  <Typography
    fontSize={14}
    fontWeight={700}
    style={{ color: 'var(--accent-primary-on-accent-primary)' }}
    data-name="app-banner-title"
  >
    {children}
  </Typography>
)

export const Subtitle: FC<PropsWithChildren> = ({ children }) => (
  <Typography
    fontSize={11}
    fontWeight={400}
    style={{ color: 'var(--accent-primary-on-accent-primary)' }}
  >
    {children}
  </Typography>
)

export const DownloadButton: FC<PropsWithChildren> = ({ children }) => (
  <PrimaryButton
    size="S"
    color="white"
    border={false}
    withPadding={false}
    data-name="app-banner-install"
  >
    {children}
  </PrimaryButton>
)
