import { css } from 'styled-components'

import { StyledComponentsStyles } from 'common/types'
import { zIndex } from 'components/presentational/zIndex'

export const containerCss = css<{
  $styles: StyledComponentsStyles
}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${zIndex.installAppBanner};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: var(--spacing-8px);
  padding-bottom: var(--spacing-8px);
  padding-right: var(--spacing-8px);
  ${({ $styles }) => $styles};
  text-decoration: none;
  cursor: pointer;
`
