export const buttonResetStyles = `
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  outline: none;
  overflow: visible;
  cursor: pointer;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: inherit;
  text-align: center;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-tap-highlight-color: transparent;
`
